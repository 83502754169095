import type { PhoneConfiguration, PhoneConfigurationPayload, TicketType } from '@eeedo/types';
import type { History } from 'history';

import { getSearchParams, getTicket, getTicketType } from 'src/Utilities/helper';

import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { Ticket } from 'src/types/Ticket';

const escapeRegExp = (s: string) => {
  return s.replace(/[-/\\$*+?.()|[\]{}]/g, '\\$&');
};

export const findPhoneConfiguration = (configurations: PhoneConfiguration[], serviceName?: string) => {
  if (!serviceName) {
    return;
  }

  return configurations.find((configuration) => {
    const escapedValue = escapeRegExp(serviceName);
    return configuration.value.match(new RegExp(escapedValue, 'g'));
  });
};

export const parsePhoneConfiguration = (
  configuration?: PhoneConfiguration
): PhoneConfigurationPayload | Partial<PhoneConfigurationPayload> => {
  if (!configuration) {
    return {};
  }

  return typeof configuration.configuration === 'string'
    ? JSON.parse(configuration.configuration)
    : configuration.configuration;
};

export const getPhoneConfigTicketType = (
  parsedConfiguration: PhoneConfigurationPayload | Partial<PhoneConfigurationPayload>,
  ticketTypes: TicketType[]
) => {
  const taskType = parsedConfiguration.taskType;
  if (!taskType) {
    return;
  }

  return ticketTypes.find((ticketType) => ticketType.id === parseInt(String(taskType), 10));
};

export const getPhoneConfigTags = (
  parsedConfiguration: PhoneConfigurationPayload | Partial<PhoneConfigurationPayload>
) => {
  if (!parsedConfiguration.tags) {
    return;
  }

  return parsedConfiguration.tags.map((tag) => {
    if (typeof tag === 'string' && tag.includes('TAG')) {
      return tag;
    }
    return `TAG${tag}`;
  });
};

export const getIntegrationData = ({
  UID,
  configuration,
  detailsObjects,
  direction,
  integrationPayload,
  history,
  normalizedPhoneNumber,
  parsedConfiguration,
  serviceName,
  ticketTypes,
  userDefaultTicketTypeId
}: {
  UID: string;
  configuration?: PhoneConfiguration;
  detailsObjects: UpdateDetailsObject[];
  direction: 'in' | 'out';
  integrationPayload?: IntegrationData['integrationPayload'];
  history: History;
  normalizedPhoneNumber: string;
  parsedConfiguration: PhoneConfigurationPayload | Partial<PhoneConfigurationPayload>;
  serviceName: string;
  ticketTypes: TicketType[];
  userDefaultTicketTypeId: number;
}): IntegrationData => {
  const ticketType = getTicketType(serviceName, ticketTypes, userDefaultTicketTypeId);
  const configTicketType = getPhoneConfigTicketType(parsedConfiguration, ticketTypes) || ticketType;
  const configTags = getPhoneConfigTags(parsedConfiguration);
  const searchObject = getSearchParams(normalizedPhoneNumber, configTicketType);

  const confObject = configuration
    ? {
        ...parsedConfiguration,
        ticketType: configTicketType,
        tags: configTags
      }
    : undefined;

  const ticketData = getTicket(ticketType, confObject);

  return {
    UID,
    detailsObjects,
    direction,
    integrationPayload,
    history,
    normalizedPhoneNumber,
    searchObject,
    ticketData: {
      ...ticketData,
      originalDirection: direction
    } as Ticket
  };
};

export const shouldCreateAPhoneCallTicket = (
  configuration: PhoneConfiguration | undefined,
  parsedConfiguration: PhoneConfigurationPayload | Partial<PhoneConfigurationPayload>
): boolean => {
  return !configuration || (configuration && !parsedConfiguration.noTicket);
};
